import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';
import { Main } from './Main/Main';
import { GoldenEgg } from './Projects/GoldenEgg/GoldenEgg';
import { Trice } from './Projects/Trice/Trice';
import { DataSpike } from './Projects/DataSpike/DataSpike';
import { Crpp } from './Projects/Crpp/Crpp';
import { GoMining } from './Projects/GoMining/GoMining';
import { Infomediji } from './Projects/Infomediji/Infomediji';
import { ContactUsForm } from './ContactUsForm/ContactUsForm';
import { InfoPopup } from './InfoPopup/InfoPopup';

function App() {
  const history = useHistory();
  const location = useLocation();
  const body = document.getElementById('body');
  const [isContactUsFormVisible, setContactUsFormVisibility] = useState(false);
  const [isInfoPopupVisible, setInfoPopupVisibility] = useState(false);
  const [isError, setError] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location])

  // function openMenu() {
  //   setMenuOpen(true);
  //   body.classList.add('overflow-hidden');
  // }

  function ContactUsFormVisible() {
    setContactUsFormVisibility(!isContactUsFormVisible);
    body.classList.toggle('overflow-hidden');
  }

  function InfoPopupVisible() {
    setInfoPopupVisibility(!isInfoPopupVisible);
    body.classList.toggle('overflow-hidden');
  }

  return (
    <div className="page">
      <Header
        history={history}
        contactUsForm={ContactUsFormVisible}
      />

      <Switch>
        <Route exact path='/'>
          <Main
            history={history}
          />
        </Route>

        <Route exact path='/projects/crpp'>
          <Crpp
            history={history}
          />
        </Route>

        <Route exact path='/projects/golden-egg'>
          <GoldenEgg
            history={history}
          />
        </Route>

        <Route exact path='/projects/trice'>
          <Trice
            history={history}
          />
        </Route>

        <Route exact path='/projects/dataspike'>
          <DataSpike
            history={history}
          />
        </Route>

        <Route exact path='/projects/go-mining'>
          <GoMining
            history={history}
          />
        </Route>

        <Route exact path='/projects/infomediji'>
          <Infomediji
            history={history}
          />
        </Route>
      </Switch>

      <Footer
        contactUsForm={ContactUsFormVisible}
      />

      <ContactUsForm
        isOpen={isContactUsFormVisible}
        contactUsForm={ContactUsFormVisible}
        infoPopup={InfoPopupVisible}
        error={setError}
      />

      <InfoPopup
        isOpen={isInfoPopupVisible}
        infoPopup={InfoPopupVisible}
        error={isError}
      />
    </div>
  );
}

export default App;

import { useState } from 'react';

export const ContactUsForm = (props) => {
    const [name, setName] = useState('');
    const [contact, setContact] = useState('');
    const [about, setAbout] = useState('');
    const [checkedBranding, setCheckedBranding] = useState(false);
    const [checkedGraphicDesign, setCheckedGraphicDesign] = useState(false);
    const [checkedLanding, setCheckedLanding] = useState(false);
    const [checkedSite, setCheckedSite] = useState(false);
    const [checkedPlatform, setCheckedPlatform] = useState(false);
    const [checkedApp, setCheckedApp] = useState(false);
    const [checkedDesignSupport, setCheckedDesignSupport] = useState(false);
    const [radioText, setRadioText] = useState('');

    const telegramBotToken = '6111293374:AAHqy2uHKxte8vvMDxiOcgRp4CdinLV_khU';
    const telegramChatId = '-1001901612835';
    const telegramApi = `https://api.telegram.org/bot${telegramBotToken}/sendMessage`;

    function handleChangeName(e) {
        setName(e.target.value);
    }

    function handleChangeContact(e) {
        setContact(e.target.value);
    }

    function handleChangeAbout(e) {
        setAbout(e.target.value);
    }

    function handleChangeCheckboxBranding() {
        setCheckedBranding(!checkedBranding);
    }

    function handleChangeCheckboxGraphicDesign() {
        setCheckedGraphicDesign(!checkedGraphicDesign);
    }

    function handleChangeCheckboxLanding() {
        setCheckedLanding(!checkedLanding);
    }

    function handleChangeCheckboxSite() {
        setCheckedSite(!checkedSite);
    }

    function handleChangeCheckboxPlatform() {
        setCheckedPlatform(!checkedPlatform);
    }

    function handleChangeCheckboxApp() {
        setCheckedApp(!checkedApp);
    }

    function handleChangeCheckboxDesignSupport() {
        setCheckedDesignSupport(!checkedDesignSupport);
    }

    function changeRadioText(e) {
        setRadioText(e.target.value);
    }

    async function sendTelegram(e) {
        e.preventDefault();

        // const formBtn = document.querySelector('.contact-us-form__submit-button');
        const textCheckbox = `${checkedBranding ? 'Брендинг' : ''} ${checkedGraphicDesign ? 'Графический дизайн' : ''} ${checkedLanding ? 'Лендинг' : ''} ${checkedSite ? 'Сайт' : ''} ${checkedPlatform ? 'Платформа' : ''} ${checkedApp ? 'Приложение' : ''} ${checkedDesignSupport ? 'Дизайн поддержка' : ''}`;
        const text = `Имя и компания: ${name}\nТелефон или телеграм: ${contact}\nО проекте: ${about}\nУслуги: ${textCheckbox}\nБюджет: ${radioText}`;

        try {
            // formBtn.textContent = 'Loading...';
            const response = await fetch(telegramApi, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    chat_id: telegramChatId,
                    text: text,
                })
            });

            if (response.ok) {
                setName('');
                setContact('');
                setAbout('');
                setCheckedBranding(false);
                setCheckedGraphicDesign(false);
                setCheckedLanding(false);
                setCheckedSite(false);
                setCheckedPlatform(false);
                setCheckedApp(false);
                setCheckedDesignSupport(false);
                setRadioText('');
                props.contactUsForm();
                props.infoPopup();
                props.error(false);
                // props.setInfoPopup(true);
                // props.openPopup(true);
            } else {
                throw new Error(response.statusText);
            }
        } catch (error) {
            // props.setInfoPopup(false);
            // props.openPopup(true);
            props.contactUsForm();
            props.error(true);
            console.error(error);
        } finally {
            // formBtn.textContent = 'Submit the Request';
        }
    }

    return (
        <div className={`contact-us-form ${props.isOpen ? 'contact-us-form_type_is-open' : ''}`}>
            <div className="contact-us-form__content">
                <div className="contact-us-form__title-container">
                    <p className="contact-us-form__title typography typography__body-l">Заполните форму обратной связи, чтобы начать диалог с нами</p>

                    <div className="contact-us-form__close-button-container">
                        <button onClick={props.contactUsForm} className="contact-us-form__close-button">
                            <svg xmlns="http://www.w3.org/2000/svg" fontSize='inherit' viewBox="0 0 36 36" fill="none">
                                <path d="M35.9999 35.294L35.294 35.9999L18.0001 18.7059L0.705956 36.0001L7.53882e-05 35.2942L17.2942 18.0001L0 0.705881L0.705881 0L18.0001 17.2942L35.2941 0.00013007L36 0.706011L18.7059 18.0001L35.9999 35.294Z" fill='currentColor'/>
                            </svg>
                        </button>
                    </div>
                </div>

                <form onSubmit={sendTelegram} className="contact-us-form__form">
                    <div className="contact-us-form__form-content">
                        <div className="contact-us-form__name-inputs-container">
                            <div className="contact-us-form__input-container">
                                <p className={`contact-us-form__input-text typography typography__body-xs ${name==='' ? '' : 'contact-us-form__input-text_type_visible'}`}>Имя и компания</p>
                                <input
                                    className="contact-us-form__input typography typography__input"
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="Имя и компания"
                                    value={name || ''}
                                    onChange={handleChangeName}
                                    minLength="1"
                                    maxLength="40"
                                    required
                                />
                            </div>

                            <div className="contact-us-form__input-container">
                                <p className={`contact-us-form__input-text typography typography__body-xs ${contact==='' ? '' : 'contact-us-form__input-text_type_visible'}`}>Телефон или телеграм</p>
                                <input
                                    className="contact-us-form__input typography typography__input"
                                    type="text"
                                    name="contact"
                                    id="contact"
                                    placeholder="Телефон или телеграм"
                                    value={contact || ''}
                                    onChange={handleChangeContact}
                                    minLength="1"
                                    maxLength="40"
                                    required
                                />
                            </div>
                        </div>

                        <div className="contact-us-form__services-content">
                            <p className="typography typography__body-s">Какие услуги вам необходимы?</p>

                            <div className="contact-us-form__services-container">
                                <label className="contact-us-form__checkbox typography typography__body-s">Брендинг
                                    <input className="contact-us-form__checkbox-input" type="checkbox" checked={checkedBranding} onChange={handleChangeCheckboxBranding} />
                                    <span className="contact-us-form__checkbox-span">Брендинг</span>
                                </label>

                                <label className="contact-us-form__checkbox typography typography__body-s">Графический дизайн
                                    <input className="contact-us-form__checkbox-input" type="checkbox" checked={checkedGraphicDesign} onChange={handleChangeCheckboxGraphicDesign} />
                                    <span className="contact-us-form__checkbox-span">Графический дизайн</span>
                                </label>

                                <label className="contact-us-form__checkbox typography typography__body-s">Лендинг
                                    <input className="contact-us-form__checkbox-input" type="checkbox" checked={checkedLanding} onChange={handleChangeCheckboxLanding} />
                                    <span className="contact-us-form__checkbox-span">Лендинг</span>
                                </label>

                                <label className="contact-us-form__checkbox typography typography__body-s">Сайт
                                    <input className="contact-us-form__checkbox-input" type="checkbox" checked={checkedSite} onChange={handleChangeCheckboxSite} />
                                    <span className="contact-us-form__checkbox-span">Сайт</span>
                                </label>

                                <label className="contact-us-form__checkbox typography typography__body-s">Платформа
                                    <input className="contact-us-form__checkbox-input" type="checkbox" checked={checkedPlatform} onChange={handleChangeCheckboxPlatform} />
                                    <span className="contact-us-form__checkbox-span">Платформа</span>
                                </label>

                                <label className="contact-us-form__checkbox typography typography__body-s">Приложение
                                    <input className="contact-us-form__checkbox-input" type="checkbox" checked={checkedApp} onChange={handleChangeCheckboxApp} />
                                    <span className="contact-us-form__checkbox-span">Приложение</span>
                                </label>

                                <label className="contact-us-form__checkbox typography typography__body-s">Дизайн поддержка
                                    <input className="contact-us-form__checkbox-input" type="checkbox" checked={checkedDesignSupport} onChange={handleChangeCheckboxDesignSupport} />
                                    <span className="contact-us-form__checkbox-span">Дизайн поддержка</span>
                                </label>
                            </div>
                        </div>

                        <div className="contact-us-form__services-content">
                            <p className="typography typography__body-s">Примерный бюджет проекта</p>

                            <div className="contact-us-form__services-container">
                                <div className="contact-us-form__radio-button">
	                                <input id="radio-1" type="radio" name="radio" value="до 200 тыс. ₽" checked={radioText === 'до 200 тыс. ₽' ? true : false} onChange={changeRadioText} />
	                                <label className="typography typography__body-s" htmlFor="radio-1">до 200 тыс. ₽</label>
                                </div>

                                <div className="contact-us-form__radio-button">
	                                <input id="radio-2" type="radio" name="radio" value="200 – 500 тыс. ₽" checked={radioText === '200 – 500 тыс. ₽' ? true : false} onChange={changeRadioText} />
	                                <label className="typography typography__body-s" htmlFor="radio-2">200 – 500 тыс. ₽</label>
                                </div>

                                <div className="contact-us-form__radio-button">
	                                <input id="radio-3" type="radio" name="radio" value="500 тыс. - 1 млн. ₽" checked={radioText === '500 тыс. - 1 млн. ₽' ? true : false} onChange={changeRadioText} />
	                                <label className="typography typography__body-s" htmlFor="radio-3">500 тыс. - 1 млн. ₽</label>
                                </div>

                                <div className="contact-us-form__radio-button">
	                                <input id="radio-4" type="radio" name="radio" value="более 1 млн. ₽" checked={radioText === 'более 1 млн. ₽' ? true : false} onChange={changeRadioText} />
	                                <label className="typography typography__body-s" htmlFor="radio-4">более 1 млн. ₽</label>
                                </div>
                            </div>
                        </div>

                        <div className="contact-us-form__input-container">
                            <p className={`contact-us-form__input-text typography typography__body-xs ${about==='' ? '' : 'contact-us-form__input-text_type_visible'}`}>Расскажите о вашем проекте</p>
                            <input
                                className="contact-us-form__input typography typography__input"
                                type="text"
                                name="about"
                                id="about"
                                placeholder="Расскажите о вашем проекте"
                                value={about || ''}
                                onChange={handleChangeAbout}
                                minLength="1"
                                maxLength="300"
                            />
                        </div>
                    </div>

                    <button type="submit" className="contact-us-form__submit-button">
                        <svg xmlns="http://www.w3.org/2000/svg" fontSize='inherit' viewBox="0 0 160 160" fill="none">
                            <rect width="160" height="160" rx="80" fill='currentColor'/>

                            <g clipPath="url(#clip0_1795_23785)">
                                <path fillRule="evenodd" clipRule="evenodd" d="M99.956 59.9924L55.5762 76.5044L79.0228 81.0413L84.8283 104.23L99.956 59.9924ZM79.6064 78.7097L64.4262 75.7724L93.2589 65.0449L79.6064 78.7097ZM81.3311 80.3791L85.1845 95.7706L95.1923 66.5053L81.3311 80.3791Z" fill="white"/>
                            </g>

                            <defs>
                                <clipPath id="clip0_1795_23785">
                                    <rect width="48" height="48" fill="white" transform="translate(56 56)"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </button>
                </form>
            </div>
        </div>
    );
}

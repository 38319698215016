import { HeadingInfo } from "../../Project/HeadingInfo/HeadingInfo";
import { Image } from "../../Image/Image";
import { AboutProject } from "../../Project/AboutProject/AboutProject";
import { TitleTextProjects } from "../../Project/TitleTextProjects/TitleTextProjects";
import { TextProjects } from "../../Project/TextProjects/TextProjects";
import { ProjectOtherProjects } from "../../Project/ProjectOtherProjects/ProjectOtherProjects";

import imageBigFirst from "../../../images/golden-egg-big-first.jpg";
import imageBigSecond from "../../../images/golden-egg-big-second.jpg";
import imageBigThird from "../../../images/golden-egg-big-third.jpg";
import imageBigFourth from "../../../images/golden-egg-big-fourth.jpg";
import imageBigFifth from "../../../images/golden-egg-big-fifth.jpg";
import imageBigSixth from "../../../images/golden-egg-big-sixth.jpg";
import imageBigSeventh from "../../../images/golden-egg-big-seventh.jpg";
import imageBigEighth from "../../../images/golden-egg-big-eighth.jpg";
import imageBigNinth from "../../../images/golden-egg-big-ninth.jpg";
// import imageBigTenth from "../../../images/golden-egg-big-tenth.jpg";
import imageBigEleventh from "../../../images/golden-egg-big-eleventh.jpg";
import imageBigTwelfth from "../../../images/golden-egg-big-twelfth.jpg";

import imageMediumFirst from "../../../images/golden-egg-medium-first.jpg";
import imageMediumSecond from "../../../images/golden-egg-medium-second.jpg";
import imageMediumThird from "../../../images/golden-egg-medium-third.jpg";
import imageMediumFourth from "../../../images/golden-egg-medium-fourth.jpg";
import imageMediumFifth from "../../../images/golden-egg-medium-fifth.jpg";
import imageMediumSixth from "../../../images/golden-egg-medium-sixth.jpg";
import imageMediumSeventh from "../../../images/golden-egg-medium-seventh.jpg";
import imageMediumEighth from "../../../images/golden-egg-medium-eighth.jpg";

import dataSpike from "../../../images/dataspike-big-first.jpg";
import goMining from "../../../images/main-projects-go-mining.png";

export const GoldenEgg = (props) => {
    const openDataspikePage = () => {
        props.history.push('/projects/dataspike');
    }

    const openGoMiningPage = () => {
        props.history.push('/projects/go-mining');
    }

    return (
        <div className="project">
            <HeadingInfo
                name={'Golden Egg'}
                client={['Golden Egg', 'Фудтех приложение для гастрономических гедонистов']}
                profile={['Брендинг', 'Приложение', 'Коммуникации']}
                date={'2023–2024'}
            />

            <Image
                image={imageBigFirst}
                alt={'Golden Egg'}
            />

            <AboutProject
                text={'Golden Egg — соцсеть, объединяющая рестораны, инфлюенсеров и пользователей для поиска кафе и ресторанов в России и ОАЭ.'}
            />

            <section className="project-task">
                <TitleTextProjects 
                    title={'Проект и задача'}
                    text={['Приложение упрощает выбор мест и блюд с помощью искусственного интеллекта, показывает отзывы от инфлюенсеров, помогает с продвижением и привлекает заведениям нужную аудиторию.', 'Наша задача — вдохнуть новую жизнь в продукт: полностью обновить приложение и брендинг. А также добавить онбординг, решить проблему с отсутствием регистраций в приложении и повысить вовлеченность в новостную ленту.']}
                />

                <Image
                    image={imageBigSecond}
                    alt={'Golden Egg'}
                />
            </section>

            <section className="project-task">
                <TitleTextProjects 
                    title={'Брендинг'}
                    text={['Новый дизайн', 'Одной из главных проблем старого дизайна было отсутствие единого фирменного стиля и логотипа, отражающего современность проекта. Мы разработали бренд-эстетику с логотипом, цветами и шрифтом, подчеркнув современность и технологичность проекта.']}
                />

                <div>
                    <div className="project__couple-images">
                        <Image
                            image={imageMediumFirst}
                            alt={'Golden Egg'}
                        />

                        <Image
                            image={imageMediumSecond}
                            alt={'Golden Egg'}
                        />

                        <div className="project-task__text-container project-task__text-container_type_grid-first">
                            <p className="typography typography__body-s">До</p>
                        </div>

                        <div className="project-task__text-container project-task__text-container_type_grid-second">
                            <p className="typography typography__body-s">После</p>
                        </div>
                    </div>

                    <div className="project__couple-images">
                        <Image
                            image={imageMediumThird}
                            alt={'Golden Egg'}
                        />

                        <Image
                            image={imageMediumFourth}
                            alt={'Golden Egg'}
                        />
                    </div>

                    <Image
                        image={imageBigThird}
                        alt={'Golden Egg'}
                    />
                </div>
            </section>

            <section className="golden-egg-mobile-app">
                <div className="golden-egg-mobile-app__container">
                    <TitleTextProjects
                        title={'Мобильное приложение'}
                        text={['Легкий старт', 'Наши исследования выявили проблему, что большинство пользователей не регистрировались после скачивания приложения. Мы добавили понятный онбординг, рассказывающий о ключевом функционале, и сделали регистрацию необязательной для поиска ресторанов.']}
                    />

                    <div>
                        <div className="project__couple-images">
                            <Image
                                image={imageMediumFifth}
                                alt={'Golden Egg'}
                            />

                            <Image
                                image={imageMediumSixth}
                                alt={'Golden Egg'}
                            />
                        </div>

                        <Image
                            image={imageBigFourth}
                            alt={'Golden Egg'}
                        />
                    </div>
                </div>
                <div className="golden-egg-mobile-app__container">
                    <TextProjects
                        text={['Интеллектуальный поиск', 'Поиск стал умным: теперь можно ввести любое пожелание, и система подберёт подходящий ресторан по ключевым словам. Также стало легче находить рестораны на карте. А фильтры упростили настройку результатов поиска под предпочтения пользователя.', 'Мы также обновили каталог заведений: карточки ресторанов теперь содержат быстрое бронирование столика и промоакции. Кроме того, сервис агрегирует рейтинг ресторанов из Яндекса.']}
                    />

                    <div>
                        <Image
                            image={imageBigFifth}
                            alt={'Golden Egg'}
                        />

                        <Image
                            image={imageBigSixth}
                            alt={'Golden Egg'}
                        />

                        <Image
                            image={imageBigSeventh}
                            alt={'Golden Egg'}
                        />
                    </div>
                </div>
                <div className="golden-egg-mobile-app__container">
                    <TextProjects
                        text={['Подборки лучших заведений от фуд-блогеров', 'Первое приложение с подборками лучших ресторанов от фуд-блогеров с кастомными объявлениями о новых открытиях для своих подписчиков. Пользователи также могут создавать собственные списки и шерить их с друзьями.', 'Для ресторанов доступна отправка пуш-сообщений с акциями и промо-материалами подписчикам, находящимся в определённом радиусе от заведения.']}
                    />

                    <div>
                        <div className="project__couple-images">
                            <Image
                                image={imageMediumSeventh}
                                alt={'Golden Egg'}
                            />

                            <Image
                                image={imageMediumEighth}
                                alt={'Golden Egg'}
                            />
                        </div>

                        <Image
                            image={imageBigEighth}
                            alt={'Golden Egg'}
                        />

                        <Image
                            image={imageBigNinth}
                            alt={'Golden Egg'}
                        />
                    </div>
                </div>
            </section>

            <section className="golden-egg-results">
                <TitleTextProjects
                    title={'Результат'}
                    text={['По результатам запуска обновленного дизайна, мы значительно улучшили ключевые метрики и число активных пользователей приложения. Добавленный нами онбординг сработал эффективно, и мы полностью устранили проблему отсутствия регистраций в приложении.']}
                />

                <div className="golden-egg-results__container">
                    <div className="golden-egg-results__info-container">
                        <p className="typography typography__h1">98%</p>
                        <p className="typography typography__body-s">рост регистраций в приложении</p>
                    </div>

                    <div className="golden-egg-results__info-container">
                        <p className="typography typography__h1">52%</p>
                        <p className="typography typography__body-s">рост согласий на уведомления</p>
                    </div>

                    <div className="golden-egg-results__info-container">
                        <p className="typography typography__h1">200+</p>
                        <p className="typography typography__body-s">дизайнов макетов</p>
                    </div>
                </div>
            </section>

            <section className="project-task">
                <TitleTextProjects
                    title={'Коммуникации'}
                    text={['Мы обновили визуальный стиль соцсетей, сделав его гармоничным и узнаваемым на всех площадках. А также создали простые шаблоны, которые легко использовать повторно для любых форматов.']}
                />

                <div className="project-task__container">
                    <Image
                        image={imageBigEleventh}
                        alt={'Golden Egg'}
                    />

                    <div className="golden-egg-mobile-app__container">
                        <TextProjects
                            text={['И обновили визуальный стиль социальный сетей, сделав его гармоничным и узнаваемым на всех площадках. А также создали простые шаблоны, которые легко переиспользовать повторно для любых форматов.']}
                        />

                        <Image
                            image={imageBigTwelfth}
                            alt={'Golden Egg'}
                        />
                    </div>
                </div>
            </section>

            <ProjectOtherProjects
                imageFirst={goMining}
                nameFirst={'GoMining'}
                textFirst={'Промо-сайт компании в сфере майнинга и аренды криптоферм на базе Биткоина'}
                openPageFirst={openGoMiningPage}
                imageSecond={dataSpike}
                nameSecond={'Data Spike'}
                textSecond={'Платформа для KYC верификации и AML-решений: клиенты доверяют нам уже 4 года на всех этапах роста'}
                openPageSecond={openDataspikePage}
            />
        </div>
    );
}
